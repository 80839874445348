import axios from 'axios';

import { APIS } from '@/constants';

const keycloakApi = {
  requestUsersList(params, token) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      method: 'get',
      baseURL: APIS.KEYCLOAK_USERS,
      headers,
      params,
    });
  },
  requestUserDetail(id, token) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: `/${id}`,
      method: 'get',
      baseURL: APIS.KEYCLOAK_USERS,
      headers,
    });
  },
  requestUserEvents({ id, type }, token) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const params = {
      user: id,
      type,
    };
    return axios({
      method: 'get',
      baseURL: APIS.KEYCLOAK_EVENTS,
      headers,
      params,
    });
  },
};

export default keycloakApi;
