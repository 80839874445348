import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
  },
  theme: {
    themes: {
      light: {
        primary: '#294971',
        background: colors.grey.lighten5,
        backgroundFooter: colors.blueGrey.lighten5,
      },
    },
  },
});
