const ROUTES = Object.freeze({
  PATH: {
    ACCOUNTS: '/accounts',
    ACCOUNTS_LIST: 'list',
    ACCOUNTS_DETAIL: 'detail/:accountId',
    LOGIN: '/login',
    NOT_FOUND: '/:notFound(.*)',
    ROOT: '/',
  },
  NAME: {
    ACCOUNTS: 'Accounts',
    ACCOUNTS_LIST: 'AccountsList',
    ACCOUNTS_DETAIL: 'AccountsDetail',
    NOT_FOUND: 'NotFound',
  },
  TITLE: {
    ACCOUNTS: 'Accounts',
    ACCOUNTS_LIST: 'Accounts List',
    ACCOUNTS_DETAIL: 'Accounts Detail',
    NOT_FOUND: 'Not Found',
  },
  USER_ROLE: {
    ANY: 'any',
    AUTHORIZED: 'authorized',
    NOT_AUTHORIZED: 'notAuthorized',
  },
});

export default ROUTES;
