import { initState } from './index';

export default {
  reset(state) {
    Object.assign(state, initState());
  },
  setError(state, payload) {
    state.error = payload;
  },
  setLoading(state, payload) {
    state.isLoading = payload;
  },
  setList(state, payload) {
    state.list = payload;
  },
  setDetail(state, payload) {
    state.detail = payload;
  },
  setForms(state, payload) {
    state.forms = payload;
  },
  setFetchingAsset(state, payload) {
    state.isFetchingAsset = payload;
  },
};
