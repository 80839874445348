const labels = {
  amountToPay: 'Amount to pay',
  back: 'Back',
  backupWalletKeysDate: 'Backup wallet keys date',
  certificateDate: 'Certificate date',
  check: 'Check',
  creationDate: 'Creation date',
  email: 'Email',
  firstName: 'First name',
  front: 'Front',
  getAttachment: 'Get attachment',
  getAttachments: 'Get attachments',
  lastLogin: 'Last login',
  lastName: 'Last name',
  loadingDate: 'Loading date',
  open: 'Open',
  privacyCheck: 'Privacy check',
  reservationDate: 'Reservation date',
  search: 'Search',
  signOut: 'Sign out',
  wallet: 'Wallet',
};

export default labels