<template>
  <nav>
    <v-navigation-drawer v-model="drawer" app fixed disable-resize-watcher>
      <the-drawer />
    </v-navigation-drawer>
    <v-app-bar app dark dense flat color="primary">
      <v-row class="d-flex align-center">
        <v-col cols="1" class="d-flex justify-start">
          <v-app-bar-nav-icon @click.stop="drawer = !drawer">
            <slot>
              <v-icon size="35">{{ drawer ? mdiWindowClose : mdiMenu }}</v-icon>
            </slot>
          </v-app-bar-nav-icon>
        </v-col>
        <v-col cols="10" class="d-flex justify-space-around">
          <router-link :to="{ name: ROUTES.NAME.ACCOUNTS }">
            <v-img :src="srcLogo" alt="Logo" width="200" />
          </router-link>
        </v-col>
        <v-col cols="1" class="d-flex justify-end"></v-col>
      </v-row>
    </v-app-bar>
    <v-divider />
  </nav>
</template>

<script>
import TheDrawer from './TheDrawer.vue';

import ROUTES from '@/constants/routes-constant';
import { mdiMenu, mdiWindowClose } from '@mdi/js';

export default {
  name: 'TheNavBar',
  components: { TheDrawer },
  data() {
    return {
      ROUTES,
      mdiMenu,
      mdiWindowClose,
      drawer: false,
    };
  },
  computed: {
    srcLogo() {
      return require('@/assets/img/logo-small.png');
    },
  },
};
</script>
