const BACKEND = process.env.VUE_APP_BACKEND;
const BACKEND_PATH = `${BACKEND}/api/v1`;

const KEYCLOAK_REALM = process.env.VUE_APP_KEYCLOAK_REALM;
const KEYCLOAK_URL = process.env.VUE_APP_KEYCLOAK_URL;
const KEYCLOAK_PATH = `${KEYCLOAK_URL}admin/realms/${KEYCLOAK_REALM}`;

const APIS = Object.freeze({
  FOX_CONFIGURATIONS: `${BACKEND_PATH}/configurations`,
  FOX_FILES: `${BACKEND_PATH}/files`,
  KEYCLOAK_EVENTS: `${KEYCLOAK_PATH}/events`,
  KEYCLOAK_USERS: `${KEYCLOAK_PATH}/users`,
});

export default APIS;
