import { KEYCLOAK_CONFIG, ROUTES } from '@/constants';
import { loadPage } from '../index';

const AccountsDetailPage = loadPage('accounts/AccountsDetailPage');
const AccountsListPage = loadPage('accounts/AccountsListPage');
const AccountsPage = loadPage('accounts/AccountsPage');

const accountsRoute = {
  path: ROUTES.PATH.ACCOUNTS,
  component: AccountsPage,
  meta: {
    auth: ROUTES.USER_ROLE.AUTHORIZED,
    tags: [KEYCLOAK_CONFIG.USER_ATTRIBUTE.VALUE],
    title: ROUTES.TITLE.ACCOUNTS,
  },
  children: [
    {
      path: '',
      name: ROUTES.NAME.ACCOUNTS,
      component: AccountsListPage,
      meta: {
        auth: ROUTES.USER_ROLE.AUTHORIZED,
        tags: [KEYCLOAK_CONFIG.USER_ATTRIBUTE.VALUE],
      },
    },
    {
      path: ROUTES.PATH.ACCOUNTS_DETAIL,
      name: ROUTES.NAME.ACCOUNTS_DETAIL,
      component: AccountsDetailPage,
      props: true,
      meta: {
        auth: ROUTES.USER_ROLE.AUTHORIZED,
        tags: [KEYCLOAK_CONFIG.USER_ATTRIBUTE.VALUE],
        title: ROUTES.TITLE.ACCOUNTS_DETAIL,
      },
    },
    {
      path: ROUTES.PATH.ACCOUNTS_LIST,
      name: ROUTES.NAME.ACCOUNTS_LIST,
      component: AccountsListPage,
      meta: {
        auth: ROUTES.USER_ROLE.AUTHORIZED,
        tags: [KEYCLOAK_CONFIG.USER_ATTRIBUTE.VALUE],
        title: ROUTES.TITLE.ACCOUNTS_LIST,
      },
    },
  ],
};

export default accountsRoute;
