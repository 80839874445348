const titles = {
  accounts: 'Accounts',
  activity: 'Activity',
  addressCheck: 'Address check',
  agreements: 'Agreements',
  amlQuestionnaire: 'Aml questionnaire',
  bill: 'Bill',
  documentIdentification: 'Document identification',
  identificationDueDiligence: 'Identification and due diligence',
  kic: 'KIC',
  privacy: 'Privacy',
  quiz: 'Quiz',
  tokenBookings: 'Token bookings',
  tos: 'TOS (Term of services)',
  verifiedWallet: 'Verified Wallet',
  wallet: 'Wallet',
};

export default titles;