<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <span class="h6-text" v-text="title" />
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <slot />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  name: 'BaseExpansionPanel',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
