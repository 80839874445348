const LOCALE = Object.freeze({
  BROWSER_STORAGE_KEY: 'rivcoin-locale',
  LOCALES: [
    {
      default: true,
      value: 'en',
      text: 'English',
      label: 'EN',
      flag: 'gb',
    },
    {
      default: false,
      value: 'it',
      text: 'Italiano',
      label: 'IT',
      flag: 'it',
    },
  ],
});

export default LOCALE;
