const CONFIG = Object.freeze({
  ID: 'rivcoin',
  MODEL: {
    ID: {
      ADDRESS: 'utility_bill',
      AML: 'duediligence_questionnaire',
      BACKUP_VERIFICATION: 'backup_verification',
      KIC: 'identification',
      PRIVACY: 'privacy',
      PURCHASE: 'purchase_riv',
      TOS: 'tos',
    },
  },
  ASSET: {
    ID: {
      BILL: 'utilityBill.png',
      DOC_BACK: 'backId.png',
      DOC_FRONT: 'frontId.png',
      VIDEO: 'videoFace.mp4',
    },
  },
});

export default CONFIG;
