const labels = {
  amountToPay: 'Importo da pagare',
  back: 'Retro',
  backupWalletKeysDate: 'Data backup chiavi del wallet',
  certificateDate: 'Data del certificato',
  check: 'Verifica',
  creationDate: 'Data creazione',
  email: 'Email',
  firstName: 'Nome',
  front: 'Fronte',
  getAttachment: 'Ottieni allegato',
  getAttachments: 'Ottieni allegati',
  lastLogin: 'Ultimo login',
  lastName: 'Cognome',
  loadingDate: 'Data di caricamento',
  open: 'Apri',
  privacyCheck: 'Verifica della privacy',
  reservationDate: 'Data di prenotazione',
  search: 'Cerca',
  signOut: 'Esci',
  wallet: 'Wallet',
};

export default labels