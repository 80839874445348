import axios from 'axios';

import APIS from '@/constants/apis-constant';

const configurationsApi = {
  requestUserForms({ configuration_id, user_id }, token) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: `/${configuration_id}/user/${user_id}/forms`,
      method: 'get',
      headers,
      baseURL: APIS.FOX_CONFIGURATIONS,
    });
  },
  requestUserFormsById({ configuration_id, user_id, form_id, params }, token) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: `/${configuration_id}/user/${user_id}/forms/${form_id}/`,
      method: 'get',
      headers,
      baseURL: APIS.FOX_CONFIGURATIONS,
      params,
      responseType: 'arraybuffer',
    });
  },
};

export default configurationsApi;
