import { ROUTES } from '@/constants';
import { loadPage } from '../index';

const NotFoundAlert = loadPage('not-found/NotFoundAlert');
const NotFoundPage = loadPage('not-found/NotFoundPage');

const notFoundRoute = {
  path: ROUTES.PATH.NOT_FOUND,
  component: NotFoundPage,
  meta: {
    auth: ROUTES.USER_ROLE.ANY,
    title: ROUTES.TITLE.NOT_FOUND,
  },
  children: [
    {
      path: '',
      name: ROUTES.NAME.NOT_FOUND,
      component: NotFoundAlert,
      meta: {
        auth: ROUTES.USER_ROLE.ANY,
      },
    },
    {
      path: '*',
      redirect: ROUTES.PATH.NOT_FOUND,
    },
  ],
};

export default notFoundRoute;
