<template>
  <v-list-item-group>
    <BaseDrawerMenuItem
      :icon="mdiAccountMultipleOutline"
      :title="$t('titles.accounts')"
      :to="{ name: ROUTES.NAME.ACCOUNTS }"
    />
  </v-list-item-group>
</template>

<script>
import BaseDrawerMenuItem from './BaseDrawerMenuItem.vue';

import { mdiAccountMultipleOutline } from '@mdi/js';
import { ROUTES } from '@/constants';

export default {
  name: 'TheDrawerRoutes',
  components: { BaseDrawerMenuItem },
  data() {
    return {
      ROUTES,
      mdiAccountMultipleOutline,
    };
  },
};
</script>
