import Vue from 'vue';
import Vuex from 'vuex';

import accounts from './accounts';
import application from './application';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    // root
    application,
    // namespaced
    accounts,
  },
});
