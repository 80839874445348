<template>
  <v-list dense nav>
    <the-drawer-locale />
    <v-divider class="ma-1" />
    <the-drawer-account />
    <the-drawer-signout />
    <the-drawer-routes v-if="isAdministrator" />
  </v-list>
</template>

<script>
import TheDrawerAccount from './drawer/TheDrawerAccount.vue';
import TheDrawerLocale from './drawer/TheDrawerLocale.vue';
import TheDrawerRoutes from './drawer/TheDrawerRoutes.vue';
import TheDrawerSignout from './drawer/TheDrawerSignout.vue';

import { mapGetters } from 'vuex';
import { KEYCLOAK_CONFIG } from '@/constants';

export default {
  name: 'TheDrawer',
  components: {
    TheDrawerAccount,
    TheDrawerLocale,
    TheDrawerRoutes,
    TheDrawerSignout,
  },
  computed: {
    ...mapGetters(['userAttribute']),
    isAdministrator() {
      return this.userAttribute === KEYCLOAK_CONFIG.USER_ATTRIBUTE.VALUE;
    },
  },
};
</script>
