<template>
  <v-list-item-group>
    <v-list-item @click="logOut">
      <v-list-item-icon>
        <v-icon color="amber darken-4">{{ mdiLogout }}</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="$t('labels.signOut')" />
      </v-list-item-content>
    </v-list-item>
    <v-divider class="ma-1" />
  </v-list-item-group>
</template>

<script>
import { mdiLogout } from '@mdi/js';
import { mapActions } from 'vuex';

export default {
  name: 'TheDrawerSignout',
  data() {
    return { mdiLogout };
  },
  methods: {
    ...mapActions(['logOut']),
  },
};
</script>
