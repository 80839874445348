const titles = {
  accounts: 'Accounts',
  activity: 'Activity',
  addressCheck: 'Verifica indirizzo',
  agreements: 'Consensi',
  amlQuestionnaire: 'Questionario Aml',
  bill: 'Fattura',
  documentIdentification: 'Identificazione con documento',
  identificationDueDiligence: 'Identification e due diligence',
  kic: 'KIC',
  privacy: 'Privacy',
  quiz: 'Quiz',
  tokenBookings: 'Prenotazione token',
  tos: 'TOS (Term of services)',
  verifiedWallet: 'Wallet verificato',
  wallet: 'Wallet',
};

export default titles;