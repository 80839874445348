<template>
  <video :id="id" class="video-js vjs-default-skin vjs-big-play-centered">
    <p class="vjs-no-js">
      To view this video please enable JavaScript, and consider upgrading to a
      web browser that
      <a href="https://videojs.com/html5-video-support/" target="_blank">
        supports HTML5 video
      </a>
    </p>
  </video>
</template>

<script>
import videojs from 'video.js';

export default {
  name: 'BaseVideoPlayer',
  props: {
    id: {
      type: String,
      default: 'video-player',
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      player: null,
    };
  },
  mounted() {
    this.player = videojs(this.id, this.options);
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>

<style>
@import 'video.js/dist/video-js.css';
</style>
