import { KEYCLOAK_CONFIG, ROUTES } from '@/constants';

const loginRoute = {
  path: ROUTES.PATH.LOGIN,
  meta: {
    auth: ROUTES.USER_ROLE.ANY,
  },
  beforeEnter: () => {
    const location = `${KEYCLOAK_CONFIG.URL}realms/${KEYCLOAK_CONFIG.REALM}/protocol/openid-connect/auth?client_id=${KEYCLOAK_CONFIG.CLIENT_ID}&response_type=code&scope=openid&redirect_uri=${process.env.VUE_APP_HOST}`;
    window.location.replace(location);
  },
};

export default loginRoute;
