import Vue from 'vue';

import App from './App.vue';
import i18n from './locale';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import BaseExpansionPanel from '@/components/BaseExpansionPanel';
import BaseListItem from '@/components/BaseListItem';
import BaseVideoPlayer from '@/components/BaseVideoPlayer';

Vue.component('base-expansion-panel', BaseExpansionPanel);
Vue.component('base-list-item', BaseListItem);
Vue.component('base-video-player', BaseVideoPlayer);

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
